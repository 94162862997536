import branch from '@/api/branch'
import service from '@/api/service/service'
import shop from '@/api/shop'
import userRole from '@/api/userRole'
import { i18n } from '@/plugins/i18n'
import router from '@/router'
import store from '@/store'
import { useRouter } from '@core/utils'
import { onMounted, ref, watch } from '@vue/composition-api'

export default function useServiceUsage() {
  const { route } = useRouter()
  const dialogEdit = ref(false)
  const dialog = ref(false)
  const dialogCancel = ref(false)
  const selectedItem = ref(1)
  const items = ref([])
  const settings = ref([])
  const opdId = ref('')
  const customerData = ref({})
  const customerList = ref([])
  const selectCustomer = ref('')
  const clinicSelected = ref('')
  const search = ref('')
  const serve_id = ref(route.value.query.id)
  const courseList = ref([])
  const courseSelect = ref('')
  const courseCheck = ref([])
  const dialogInputAmount = ref(false)
  const statusDisabledInputAmount = ref(false)
  const subAmount = ref(0)
  const courseListFull = ref([])
  const courseForCusSelcet = ref('')
  const courseForCusSelectList = ref([])
  const selected = ref('')
  const myCourseList = ref([])
  const resourceDrugEquipment = ref({})
  const drugEquipmentEdit = ref([])
  const statusSelectCourse = ref(1)
  const oldCourseList = ref([])
  const drugList = ref([])
  const drugSelect = ref('')
  const equipmentList = ref([])
  const equipmentSelect = ref('')
  const statusLoadListDrugEdit = ref(false)
  const servingIdList = ref([])
  const statusDeleteCourse = ref(false)
  const newList = ref([])
  const servingEditId = ref('')
  const drug_id_pri = ref('')
  const drugEquipmentFeeData = ref([])
  const doctorList = ref([])
  const selectDoctor = ref('')
  const staffList = ref([])
  const staffSelect = ref('')
  const doctorOfCourseList = ref([])
  const staffOfCourseList = ref([])
  const statusCourseType = ref(0)
  const total_doctor_fee = ref(0)
  const total_staff_fee = ref(0)
  const serving_cost = ref(0)
  const oldServingIdList = ref([])
  const serve_date = ref(route.value.query.date)
  const cus_id = ref(route.value.query.cus_id)
  const serve_comment = ref('')
  const clinicSelectedCus = ref(route.value.query.shop_id)
  const statusCurrentShop = ref(false)
  const statusAddDrug = ref(false)
  const doctorFee = ref(0)
  const addCartLoading = ref(false)
  const loading = ref(true)
  const cancelLoading = ref(false)
  const useServiceLoading = ref(false)
  const loadingEditAmount = ref(false)
  const shop_id_pri = ref(localStorage.getItem('shop_id'))
  const dataBranchList = ref([])

  const { shop: shopGet } = shop
  const {
    serviceCustomerList,
    serviceCourseList,
    serviceServeList,
    serviceDoctorList,
    serviceCourseDrugList,
    serviceDrugAll,
    serviceUserList,
    serviceSumServe,
    serviceServe,
    serviceServiceCourse,
    serviceCancel,
    serviceUserDoctor,
    serviceUserDoctorDelete,
    serviceAddStaff,
    serviceAddDoctor,
    serviceDeleteCourse,
    serviceCheckDrug,
    serviceAddCartCourse,
    serviceDeleteDrugCourse,
    serviceUpdateDrugAmount,
    serviceAddDrug,
    serviceSelectDrug,
    serviceDrugGet,
  } = service

  const { userRoleList } = userRole

  onMounted(() => {
    getCustomerList()
    // getServeList()
    getDoctors()
    getStaff()
    getDoctorOfCourse()
    getstaffOfCourseList()
    getSumCourse()
    resourceDrugEquipment.value.course_fee = 0
  })

  branch.branchList().then(res => {
    dataBranchList.value = res
    shopGet().then(res => {
      dataBranchList.value.unshift(res)
      shop_id_pri.value = res.shop_id_pri
    })
  })

  const updateDoctorFeeInCourse = () => {
    serviceServiceCourse({
      serving_id: servingEditId.value,
      serving_cost: serving_cost.value,
    }).then(res => {
      if (res.response) {
        store.commit('app/ALERT', {
          message: 'update_data_successfully',
          color: 'success',
        })
      } else {
        store.commit('app/ALERT', {
          message: res.message,
          color: 'error',
        })
      }
    })
  }

  const userService = () => {
    useServiceLoading.value = true
    serviceServe({
      serve_id: serve_id.value,
      customer_id_pri: selectCustomer.value,
      serve_date: serve_date.value,
      serve_comment: serve_comment.value,
      lang: i18n.locale,
    }).then(res => {
      if (res.response) {
        store.commit('app/ALERT', {
          message: 'add_data_successfully',
          color: 'success',
        })
        store.commit('app/setStatusUpdateHeader')
        router.push({
          name: 'showDetailService',
          params: { id: serve_id.value },
        })
      } else {
        store.commit('app/ALERT', {
          message: res.message,
          color: 'error',
        })
        useServiceLoading.value = false
      }
    })
  }

  const cancelAll = () => {
    cancelLoading.value = true
    serviceCancel(serve_id.value).then(res => {
      if (res.response) {
        // store.commit('app/ALERT', {
        //   message: 'delete_data_successfully',
        //   color: 'success',
        // })
        store.commit('app/setStatusUpdateHeader')
        getstaffOfCourseList()
        getDoctorOfCourse()
        clearData()
        getServeCourseList()
        getServeList()
      } else {
        store.commit('app/ALERT', {
          message: res.message,
          color: 'error',
        })
      }
      dialogCancel.value = false
      cancelLoading.value = false
    })
  }

  const updateCourseStaffOrDoctor = item => {
    serviceUserDoctor({
      servinguser_id: item.servinguser_id,
      servinguser_cost: item.servinguser_cost,
    }).then(res => {
      if (res.response) {
        store.commit('app/ALERT', {
          message: 'update_data_successfully',
          color: 'success',
        })
      } else {
        store.commit('app/ALERT', {
          message: res.message,
          color: 'error',
        })
      }
      getSumCourse()
    })
  }

  const getSumCourse = () => {
    serviceSumServe(serve_id.value).then(res => {
      total_doctor_fee.value = res.sum_doctor
      total_staff_fee.value = res.sum_user
    })
  }

  const deleteStaffOrDoctor = servinguser_id => {
    serviceUserDoctorDelete(servinguser_id).then(res => {
      if (res.response) {
        store.commit('app/ALERT', {
          message: 'delete_data_successfully',
          color: 'success',
        })
        getstaffOfCourseList()
        getDoctorOfCourse()
        getDoctors()
        getStaff()
      } else {
        store.commit('app/ALERT', {
          message: res.message,
          color: 'error ',
        })
      }
      getSumCourse()
    })
  }

  const addStaff = () => {
    serviceAddStaff({
      serve_id: serve_id.value,
      user_id: staffSelect.value,
    }).then(res => {
      if (res.response) {
        store.commit('app/ALERT', {
          message: 'add_data_successfully',
          color: 'success',
        })
        getStaff()
        getstaffOfCourseList()
        getSumCourse()
      } else {
        store.commit('app/ALERT', {
          message: res.message,
          color: 'error',
        })
      }
    })
  }

  const addDoctor = () => {
    serviceAddDoctor({
      serve_id: serve_id.value,
      user_id: selectDoctor.value,
    }).then(res => {
      if (res.response) {
        store.commit('app/ALERT', {
          message: 'add_data_successfully',
          color: 'success',
        })
        getDoctors()
        getDoctorOfCourse()
        getSumCourse()
      } else {
        store.commit('app/ALERT', {
          message: res.message,
          color: 'errpr',
        })
      }
    })
  }

  const deleteServingCourse = id => {
    courseList.value = []
    oldCourseList.value = []
    courseCheck.value = []
    courseListFull.value = []
    serviceDeleteCourse(id).then(res => {
      if (res.response) {
        store.commit('app/ALERT', {
          message: 'delete_list_course_success',
          color: 'success',
        })
        getServeCourseList()
        getServeList()
        getDoctorOfCourse()
        getstaffOfCourseList()
      } else {
        store.commit('app/ALERT', {
          message: res.message,
          color: 'error',
        })
      }
      getSumCourse()
    })
  }

  const checkAddCourseToCart = () => {
    serviceCheckDrug(serve_id.value).then(res => {
      res.response ? (statusAddDrug.value = true) : (statusAddDrug.value = false)
    })
  }

  const addCourseToCart = () => {
    addCartLoading.value = true
    total_doctor_fee.value = 0
    const servingIdList = resourceDrugEquipment.value.course_list.map(item => item.serving_id)
    serviceAddCartCourse({
      serve_id: serve_id.value,
      customer_id_pri: selectCustomer.value,
      serving_id: servingIdList,
      lang: i18n.locale,
    }).then(res => {
      if (res.response) {
        store.commit('app/ALERT', {
          message: 'add_course_success',
          color: 'success',
        })
        clearData()
        getServeList()
        getServeCourseList()
        getDoctorOfCourse()
        getstaffOfCourseList()
        courseForCusSelectList.value = []
        addCartLoading.value = false
      } else {
        store.commit('app/ALERT', {
          message: res.message,
          color: 'error',
        })
        addCartLoading.value = false
      }
    })
  }

  const deleteDrugEquipmentOfCourse = id => {
    serviceDeleteDrugCourse(`${serve_id.value}/${id}`).then(res => {
      if (res.response) {
        store.commit('app/ALERT', {
          message: 'delete_drug_equipment_success',
          color: 'success',
        })
        getDrugEquipmentById(servingEditId.value, statusCourseType.value)
      } else {
        store.commit('app/ALERT', {
          message: res.message,
          color: 'error',
        })
      }
    })
  }

  const updateDrugEquipmentAmount = item => {
    loadingEditAmount.value = true
    setTimeout(() => {
      console.log('x')
      if (
        item.servingdetail_temp_amount == '0.00' ||
        (item.servingdetail_temp_amount == '0' && statusCourseType.value != 3)
      ) {
        store.commit('app/ALERT', {
          message: 'จำนวนยาต้องมากกว่าหรือเท่ากับ 1',
          color: 'error',
        })
      } else {
        serviceUpdateDrugAmount({
          servingdetail_temp_id: item.servingdetail_temp_id,
          servingdetail_temp_amount: item.servingdetail_temp_amount,
          lang: i18n.locale,
        }).then(res => {
          if (res.response) {
            store.commit('app/ALERT', {
              message: 'update_drug_equipment_success',
              color: 'success',
            })
          } else {
            store.commit('app/ALERT', {
              message: res.message,
              color: 'error',
            })
          }
          loadingEditAmount.value = false
        })
      }
    }, 1000)
  }

  const addDrugEquipment = () => {
    serviceAddDrug({
      serve_id: serve_id.value,
      serving_id: servingEditId.value,
      drug_id_pri: drug_id_pri.value,
    }).then(res => {
      if (res.response) {
        store.commit('app/ALERT', {
          message: 'add_drug_equipment_success',
          color: 'success',
        })
        getDrugEquipmentById(servingEditId.value, statusCourseType.value)
      } else {
        store.commit('app/ALERT', {
          message: res.message,
          color: 'error',
        })
      }
    })
  }

  const removeCourse = (item, status) => {
    const i = courseForCusSelectList.value.findIndex(
      subItem => subItem.course_id == item.course_id && subItem.order_id == item.order_id,
    )
    courseForCusSelectList.value.splice(i, 1)
    statusDeleteCourse.value = status
    const { serving_id } = item
    const index = servingIdList.value.findIndex(item => serving_id == item)
    servingIdList.value.splice(index, 1)
    getDrugEquipment(servingIdList.value)
    removeCourseList(item)
  }

  const removeCourseList = item => {
    const myIndex = courseListFull.value.findIndex(
      subItem => subItem.course_id == item.course_id && subItem.order_id == item.order_id,
    )
    const i = courseList.value.findIndex(
      subItem => subItem.course_id == item.course_id && subItem.order_id == item.order_id,
    )
    if (i == -1) {
      courseList.value.unshift({
        ...courseListFull.value[myIndex],
        course_amount: 1,
      })
    } else {
      courseList.value[i].course_amount += 1
    }
  }

  const getEquipmentList = id => {
    serviceSelectDrug({
      serving_id: id,
      drug_category_id: '2',
    }).then(res => {
      equipmentList.value = res
      drug_id_pri.value = ''
    })
  }

  const getDrugList = id => {
    serviceSelectDrug({
      serving_id: id,
      drug_category_id: '1',
    }).then(res => {
      drugList.value = res
      drug_id_pri.value = ''
    })
  }

  const refresh = () => {
    statusSelectCourse.value = 1
  }

  const getDrugEquipmentById = (id, type) => {
    statusCourseType.value = type
    servingEditId.value = id
    statusLoadListDrugEdit.value = false
    serviceDrugGet({
      serving_id: id,
    }).then(res => {
      // console.log('drugEquipmentEdit', res.data)
      drugEquipmentEdit.value = res.data.map(item => {
        item.servingdetail_temp_amount = type == 3 ? 0 : item.servingdetail_temp_amount

        return item
      })
      serving_cost.value = res.course_fee
      getDrugList(id)
      getEquipmentList(id)
      getDrugEquipmentAndFee(id)
      dialogEdit.value = true
      statusLoadListDrugEdit.value = true
    })
  }

  const getDrugEquipmentAndFee = id => {
    serviceDrugAll({
      serve_id: serve_id.value,
      serving_id: id,
    }).then(res => {
      drugEquipmentFeeData.value = res.data
      doctorFee.value = res.course_fee
    })
  }

  const clearData = () => {
    getDrugEquipment([])
    servingIdList.value = []
  }

  const saveEditDrug = () => {
    let chekStatus = false
    for (let i = 0; i < drugEquipmentEdit.value.length; i++) {
      if (drugEquipmentEdit.value[i].servingdetail_temp_amount == 0) {
        chekStatus = false
      } else {
        chekStatus = true
        break
      }
    }
    getDrugEquipmentAndFee(oldServingIdList.value)
    dialogEdit.value = false
    // Code ชุดนี้พี่เอ สั่ง เปลี่ยน วันที่ 3 ก.ค. 2565
    // if (chekStatus) {
    //   getDrugEquipmentAndFee(oldServingIdList.value)
    //   dialogEdit.value = false
    // } else {
    //   store.commit('app/ALERT', {
    //     message: 'ต้องมีรายการยา/อุปกณ์รายการใดรายการหนึ่งจำนวนมากกว่า 0',
    //     color: 'error',
    //   })
    // }
  }

  const getDrugEquipment = serving_id => {
    serviceCourseDrugList({
      serve_id: serve_id.value,
      customer_id_pri: selectCustomer.value,
      serving_id,
    }).then(res => {
      resourceDrugEquipment.value = res

      // romoveCourseAmount(newList.value)
      getDrugEquipmentAndFee(serving_id)
      statusSelectCourse.value = 1
    })
  }

  const romoveCourseAmount = listCourse => {
    const oldCourse = oldCourseList.value

    for (const item of listCourse) {
      const myIndex = courseList.value.findIndex(
        subItem => subItem.course_id == item.course_id && subItem.order_id == item.order_id,
      )
      if (myIndex != -1) {
        if (!statusDeleteCourse.value) {
          if (item.course_type != 2) {
            oldCourse[myIndex].course_amount -= 1
          }
        }
        if (oldCourse[myIndex].course_amount == 0) {
          oldCourse.splice(myIndex, 1)
        }
      }
    }
    courseList.value = oldCourse
  }

  const getServeList = () => {
    loading.value = true
    serviceServeList({
      serve_id: serve_id.value,
      customer_id_pri: cus_id.value,
    }).then(res => {
      myCourseList.value = res
      loading.value = false
    })
  }

  const sumCourse = () => {
    statusDeleteCourse.value = false

    // const item = []

    // for (let i = 0; i < courseListFull.value.length; i++) {
    //   if (
    //     courseListFull.value[i].course_id == courseSelect.value.course_id &&
    //     courseListFull.value[i].order_id == courseSelect.value.order_id
    //   ) {
    //     const checkServingDuplicate = courseForCusSelectList.value.findIndex(
    //       sub_item => sub_item.serving_id == courseListFull.value[i].serving_id,
    //     )
    //     if (checkServingDuplicate == -1) {
    //       item.push({ ...courseListFull.value[i], course_amount: 1 })
    //     }
    //     if (item.length == subAmount.value) {
    //       break
    //     }
    //   }
    // }
    // newList.value = item
    // courseForCusSelectList.value = [...courseForCusSelectList.value, ...item]
    // const serving_id = courseForCusSelectList.value.map(el => el.serving_id)
    getDrugEquipment(courseSelect.value)
    servingIdList.value = courseSelect.value
    oldServingIdList.value = courseSelect.value
    dialogInputAmount.value = false
    settings.value = []
    courseSelect.value = []
  }

  const showSelectCourse = () => {
    dialog.value = true
  }

  const customFilterDrugList = (item, queryText) => {
    const textOne = item.drug_id.toLowerCase()
    const textTwo = item.drug_name.toLowerCase()
    const searchText = queryText.toLowerCase()

    return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
  }

  const customFilter = (item, queryText) => {
    const textOne = item.customer_fullname.toLowerCase()
    const textTwo = item.customer_id.toLowerCase()
    const textThree = item.textserach.toLowerCase()
    const searchText = queryText.toLowerCase()

    return textOne.indexOf(searchText) > -1 || textThree.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
  }

  const customerFilterCourseList = (item, queryText) => {
    const textOne = item.course_id.toLowerCase()
    const textTwo = item.serving_name.toLowerCase()
    const searchText = queryText.toLowerCase()

    return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
  }

  // const getShop = () => {
  //   shopGet().then(res => {
  //     clinicSelected.value = res.shop_id_pri
  //     if (clinicSelectedCus.value) {
  //       if (clinicSelected.value == clinicSelectedCus.value) {
  //         statusCurrentShop.value = false
  //       } else {
  //         statusCurrentShop.value = true
  //       }
  //     } else {
  //       statusCurrentShop.value = false
  //     }
  //   })
  // }

  const getCustomerList = () => {
    serviceCustomerList({
      searchtext: '',
      shop_id_pri: clinicSelectedCus.value,
    }).then(res => {
      const forSearch = res.map(item => {
        item.textserach = `(${item.customer_id.trim()}) ${item.customer_fullname} ${
          item.customer_id_pri == cus_id.value ? `(${i18n.t('owner_list')})` : ''
        }`

        return item
      })
      customerList.value = forSearch
      selectCustomer.value = cus_id.value
      getServeCourseList()
    })
  }

  const getServeCourseList = () => {
    courseList.value = []
    oldCourseList.value = []
    courseCheck.value = []
    courseListFull.value = []
    serviceCourseList({
      serve_id: serve_id.value,
      customer_id_pri: cus_id.value,
      coursegroup_id: '',
      searchtext: search.value,
      select_shop: shop_id_pri.value,
      sub_shop_id_pri: route.value.query.shop_id,
    }).then(res => {
      courseListFull.value = res
      courseList.value = res.map(item => {
        item.serving_name = `${item.course_id} ${item.serving_name} 1 ${
          item.course_type == 1 ? i18n.t('times') : item.course_type == 2 ? i18n.t('day') : i18n.t('set')
        }`

        return item
      })
    })
  }

  const getDoctorOfCourse = () => {
    serviceDoctorList({
      serve_id: serve_id.value,
    }).then(res => {
      doctorOfCourseList.value = res
      getSumCourse()
    })
  }

  const getDoctors = () => {
    userRoleList({
      role_id: [1, 3],
    }).then(res => {
      doctorList.value = res
      doctorList.value.unshift({
        user_id: '',
        user_fullname: i18n.t('select_doctor'),
      })
      selectDoctor.value = res[0].user_id
    })
  }

  const getstaffOfCourseList = () => {
    total_staff_fee.value = 0
    serviceUserList({
      serve_id: serve_id.value,
    }).then(res => {
      staffOfCourseList.value = res
      getSumCourse()
    })
  }

  const getStaff = () => {
    userRoleList({
      role_id: [2],
    }).then(res => {
      staffList.value = res
      staffList.value.unshift({
        user_id: '',
        user_fullname: i18n.t('select_assistant_staff'),
      })
      staffSelect.value = res[0].user_id
    })
  }

  watch(dialogInputAmount, value => {
    if (!value) {
      courseSelect.value = ''
    }
  })

  watch(dialogEdit, value => {
    if (!value) {
      drugList.value = []
      equipmentList.value = []
    }
  })

  watch(search, () => {
    getServeCourseList()
  })

  watch(staffSelect, (newValue, oldValue) => {
    if (newValue != oldValue && newValue != '') {
      const indexUserId = staffOfCourseList.value.findIndex(item => item.user_id == newValue)
      if (indexUserId == -1) {
        addStaff()
      }
    }
  })

  watch(selectDoctor, (newValue, oldValue) => {
    if (newValue != oldValue && newValue != '') {
      const indexUserId = doctorOfCourseList.value.findIndex(item => item.user_id == newValue)
      if (indexUserId == -1) {
        addDoctor()
      }
    }
  })

  watch(shop_id_pri, (newValue, oldValue) => {
    if (newValue !== oldValue) {
      cancelAll()
    }
    courseSelect.value = ''
    getServeCourseList()
  })

  return {
    dialogEdit,
    dialog,
    dialogCancel,
    selectedItem,
    items,
    settings,
    opdId,
    customerData,
    customerList,
    selectCustomer,
    clinicSelected,
    search,
    serve_id,
    courseList,
    courseSelect,
    courseCheck,
    dialogInputAmount,
    statusDisabledInputAmount,
    subAmount,
    courseListFull,
    courseForCusSelcet,
    courseForCusSelectList,
    selected,
    myCourseList,
    resourceDrugEquipment,
    drugEquipmentEdit,
    statusSelectCourse,
    oldCourseList,
    drugList,
    drugSelect,
    equipmentList,
    equipmentSelect,
    statusLoadListDrugEdit,
    servingIdList,
    statusDeleteCourse,
    newList,
    servingEditId,
    dataBranchList,
    shop_id_pri,
    drug_id_pri,
    drugEquipmentFeeData,
    doctorList,
    selectDoctor,
    staffList,
    staffSelect,
    doctorOfCourseList,
    staffOfCourseList,
    statusCourseType,
    total_doctor_fee,
    total_staff_fee,
    serving_cost,
    oldServingIdList,
    serve_date,
    serve_comment,
    clinicSelectedCus,
    statusCurrentShop,
    statusAddDrug,
    cus_id,
    loading,
    doctorFee,
    // getShop,
    getCustomerList,
    getServeCourseList,
    getDoctors,
    getstaffOfCourseList,
    getDoctorOfCourse,
    getStaff,
    getSumCourse,
    updateDoctorFeeInCourse,
    userService,
    cancelAll,
    updateCourseStaffOrDoctor,
    deleteStaffOrDoctor,
    addStaff,
    addDoctor,
    deleteServingCourse,
    checkAddCourseToCart,
    addCourseToCart,
    deleteDrugEquipmentOfCourse,
    updateDrugEquipmentAmount,
    addDrugEquipment,
    removeCourse,
    removeCourseList,
    getEquipmentList,
    getDrugList,
    refresh,
    getDrugEquipmentById,
    getDrugEquipmentAndFee,
    clearData,
    saveEditDrug,
    getDrugEquipment,
    romoveCourseAmount,
    getServeList,
    sumCourse,
    showSelectCourse,
    customFilterDrugList,
    customFilter,
    customerFilterCourseList,
    loadingEditAmount,
    cancelLoading,
    useServiceLoading,
    addCartLoading,
  }
}
