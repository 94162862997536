<template>
  <div>
    <v-card>
      <v-row class='px-3 mb-2'>
        <v-col cols='12' md='6'>
          <div class='d-flex'>
            <v-autocomplete
              v-model='shop_id_pri'
              :items='dataBranchList'
              item-text='shop_name'
              item-value='shop_id_pri'
              hide-details
              outlined
              dense
              style='width: 60px'
              class='me-2'
              :label="$t('choose_a_shop')"
              :no-data-text="`${$t('no_data')} !`"
            >
            </v-autocomplete>
            <v-autocomplete
              v-model.trim='courseSelect'
              :items='courseList'
              item-text='serving_name'
              :label="$t('choose_a_course')"
              item-value='serving_id'
              multiple
              hide-details
              dense
              :filter='customerFilterCourseList'
              class='mb-4 me-2'
              :no-data-text="`${$t('no_data')} !`"
              autofocus
              outlined
            >
              <!-- <template v-slot:item="{ item }">
              ({{ item.course_id }})
              {{ item.serving_name }}
              <span class="primary--text ml-3">
                {{ item.course_amount }} {{ item.course_type == 1 ? $t('times') : item.course_type == 2 ? $t('day') :$t('set') }}
              </span>
            </template>
             -->
              <template v-slot:selection='{ item }'> ({{ item.course_id }}),</template>
            </v-autocomplete>
            <v-slide-x-reverse-transition mode='out-in'>
              <v-btn color='info' outlined @click='sumCourse'>
                {{ $t('select') }}
              </v-btn>
            </v-slide-x-reverse-transition>
          </div>
          <v-card-actions class='px-0 pb-1 font-weight-semibold'>
            {{ $t('list_of_drugs_and_equipment_used') }}
            <v-spacer></v-spacer>
            <v-btn
              color='primary'
              :disabled='!resourceDrugEquipment.course_list || addCartLoading'
              class='my-0'
              :loading='addCartLoading'
              @click='addCourseToCart'
            >
              {{ $t('add') }}
            </v-btn>
          </v-card-actions>
          <v-card outlined>
            <v-navigation-drawer height='200px' width='100%' permanent>
              <div v-if='drugEquipmentFeeData.length == 0' class='text-center mt-3'>
                <span class='font-weight-semibold'> {{ $t('no_data') }} ! </span>
              </div>
              <v-list nav>
                <v-list-item-group active-class='primary--text'>
                  <template>
                    <v-list-item v-for='(item, index) in drugEquipmentFeeData' :key='index'>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.drug_id }}
                          {{ item.drug_name }}
                          <span class='info--text'>
                            {{ item.coursedrug_amount }}
                            {{ item.drug_unit }}
                          </span>
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <DrugStatus :type='item.drug_category_id' />
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-navigation-drawer>
          </v-card>
          <v-card class='mt-4' outlined>
            <v-navigation-drawer height='230px' width='100%' permanent>
              <div v-if='!resourceDrugEquipment.course_list' class='text-center mt-3'>
                <span class='font-weight-semibold'> {{ $t('no_data') }} ! </span>
              </div>
              <v-list nav>
                <v-list-item-group v-model='selected' active-class='primary--text'>
                  <template v-for='(item, index) in resourceDrugEquipment.course_list'>
                    <v-list-item :key='index'>
                      <template v-slot:default>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ item.course_id }}
                            {{ item.course_name }}
                            <CourseType :type='item.course_type' />
                          </v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-action>
                          <div class='d-flex'>
                            <v-btn
                              color='primary'
                              icon
                              @click='getDrugEquipmentById(item.serving_id, item.course_type)'
                            >
                              <v-icon>
                                {{ icons.mdiArrowRightThick }}
                              </v-icon>
                            </v-btn>
                            <v-btn color='error' icon @click='removeCourse(item, true)'>
                              <v-icon>
                                {{ icons.mdiClose }}
                              </v-icon>
                            </v-btn>
                          </div>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-navigation-drawer>
          </v-card>
        </v-col>
        <v-divider class='my-2' vertical></v-divider>
        <v-col cols='12' md='6'>
          <v-autocomplete
            v-model='selectCustomer'
            :items='customerList'
            :filter='customFilter'
            hide-details
            item-text='textserach'
            item-value='customer_id_pri'
            :label="$t('select_customers')"
            class='mb-4'
            :disabled='statusCurrentShop'
            :no-data-text="`${$t('no_data')} !`"
            outlined
            dense
          >
          </v-autocomplete>
          <v-card-actions class='px-0 pb-1 font-weight-semibold'>
            <span class='mt-2' style='padding-bottom: 6px'>
              {{ $t('course_list') }}{{ $t('all') }}
              {{ myCourseList.length != 0 ? myCourseList.length : 0 }}
              {{ $t('list') }}
            </span>
            <v-spacer></v-spacer>
          </v-card-actions>

          <v-card outlined>
            <v-navigation-drawer height='450px' width='100%' permanent>
              <div class='text-center mt-3'>
                <span v-if='myCourseList.length == 0' class='font-weight-semibold'> {{ $t('no_data')
                  }} ! </span>
              </div>
              <v-list nav dense>
                <v-list-item-group active-class='primary--text'>
                  <template>
                    <v-list-item v-for='(item, index) in myCourseList' :key='index'>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.course_id }}
                          {{ item.serving_name }}
                          <span class='info--text'>
                            {{ item.course_amount }}
                            {{ item.course_type == 1 ? $t('times') : item.course_type == 2 ? $t('day') : $t('set')
                            }}
                          </span>
                        </v-list-item-title>

                        <v-list-item-subtitle> {{ $t('receipt_number') }} {{ item.order_id }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-icon color='error' style='cursor: pointer'
                                @click='deleteServingCourse(item.serving_id)'>
                          {{ icons.mdiDeleteOutline }}
                        </v-icon>
                      </v-list-item-action>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-navigation-drawer>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <v-card class='mt-6'>
      <v-row class='px-3 mb-2'>
        <v-col cols='12'>
          <v-row>
            <v-col cols='12' md='6'>
              <v-autocomplete
                v-model.trim='selectDoctor'
                :items='doctorList'
                :no-data-text="`${$t('no_data')} !`"
                class='set-input'
                item-text='user_fullname'
                :label="$t('select_inspectors')"
                item-value='user_id'
                hide-details
                :auto-select-first='true'
                outlined
                dense
              ></v-autocomplete>
              <v-card class='mt-3' outlined>
                <v-navigation-drawer height='250px' width='100%' permanent>
                  <div class='text-center mt-3'>
                    <span v-if='doctorOfCourseList.length == 0' class='font-weight-semibold'>
                      {{ $t('no_data') }} !
                    </span>
                  </div>
                  <v-list nav>
                    <v-list-item v-for='(item, index) in doctorOfCourseList' :key='index'>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ index + 1 }}.
                          {{ item.user_fullname }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-action-text class='d-flex'>
                          <v-text-field
                            v-model='item.servinguser_cost'
                            dense
                            outlined
                            hide-details
                            type='number'
                            :label="$t('doctor_fee')"
                            style='width: 130px'
                            @blur='updateCourseStaffOrDoctor(item)'
                            @keypress.enter='updateCourseStaffOrDoctor(item)'
                          ></v-text-field>
                          <v-btn
                            class='ml-5'
                            :disabled='item.servinguser_type_id == 2'
                            color='error'
                            icon
                            @click='deleteStaffOrDoctor(item.servinguser_id)'
                          >
                            <v-icon> {{ icons.mdiDeleteOutline }}</v-icon>
                          </v-btn>
                        </v-list-item-action-text>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-navigation-drawer>
              </v-card>
              <v-card-actions class='px-1 mt-2 pb-0'>
                <span class='font-weight-semibold'>{{ $t('total') }} {{ $t('doctor_costs') }}</span>
                <v-spacer></v-spacer>
                <span> {{ total_doctor_fee | formatPrice }} {{ $t('bath') }}</span>
              </v-card-actions>
            </v-col>
            <v-divider vertical class='mt-2'></v-divider>
            <v-col cols='12' md='6'>
              <v-autocomplete
                v-model.trim='staffSelect'
                :items='staffList'
                :no-data-text="`${$t('no_data')} !`"
                class='set-input'
                item-text='user_fullname'
                :label="$t('select_assistant_staff')"
                hide-details
                dense
                item-value='user_id'
                :auto-select-first='true'
                outlined
              ></v-autocomplete>
              <v-card class='mt-3' outlined>
                <v-navigation-drawer height='250px' width='100%' permanent>
                  <div class='text-center mt-3'>
                    <span v-if='staffOfCourseList.length == 0' class='font-weight-semibold'>
                      {{ $t('no_data') }} !
                    </span>
                  </div>
                  <v-list nav>
                    <v-list-item v-for='(item, index) in staffOfCourseList' :key='index'>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ index + 1 }}.
                          {{ item.user_fullname }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-action-text class='d-flex'>
                          <v-text-field
                            v-model='item.servinguser_cost'
                            outlined
                            dense
                            hide-details
                            type='number'
                            :label="$t('staff_costs')"
                            style='width: 130px'
                            @blur='updateCourseStaffOrDoctor(item)'
                            @keypress.enter='updateCourseStaffOrDoctor(item)'
                          ></v-text-field>
                          <v-btn
                            class='ml-5'
                            :disabled='item.servinguser_type_id == 2'
                            color='error'
                            icon
                            @click='deleteStaffOrDoctor(item.servinguser_id)'
                          >
                            <v-icon> {{ icons.mdiDeleteOutline }}</v-icon>
                          </v-btn>
                        </v-list-item-action-text>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                </v-navigation-drawer>
              </v-card>
              <v-card-actions class='px-1 mt-2 pb-0'>
                <span class='font-weight-semibold'>{{ $t('total') }} {{ $t('staff_costs') }}</span>
                <v-spacer></v-spacer>
                <span> {{ total_staff_fee | formatPrice }} {{ $t('bath') }}</span>
              </v-card-actions>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols='12' md='6'>
              <v-textarea v-model='serve_comment' outlined :label="$t('note')" rows='3'
                          hide-details></v-textarea>
            </v-col>
            <v-divider vertical class='mb-2'></v-divider>
            <v-col cols='12' md='6'>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color='primary'
                  :disabled='myCourseList.length == 0 || useServiceLoading'
                  :loading='useServiceLoading'
                  @click='userService'
                >
                  {{ $t('use_service') }}
                </v-btn>
                <v-btn color='secondary' outlined @click='dialogCancel = true'>
                  {{ $t('cancel_all') }}
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-dialog v-model='dialog' max-width='600'>
      <v-card>
        <v-card-title>
          {{ $t('course_list') }}
        </v-card-title>
        <v-divider class='mt-0'></v-divider>
        <v-card-text>
          <v-text-field v-model='search' outlined></v-text-field>
          <v-list>
            <v-list-item-group v-model='settings' multiple active-class=''>
              <v-list-item v-for='(item, index) in courseList' :key='index'>
                <template v-slot:default='{ active }'>
                  <v-list-item-action>
                    <v-checkbox :input-value='active'></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      ({{ item.course_id }})
                      {{ item.serving_name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <b> {{ $t('receipt_code') }} </b> : {{ item.order_id }} {{ $t('amount') }} :
                      {{ item.course_amount }}
                      {{ $t('times') }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions></v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model='dialogInputAmount' max-width='500'>
      <v-card>
        <v-card-title>
          {{ $t('input_amount') }}
        </v-card-title>
        <v-card-text>
          <label
          >{{ $t('input_amount') }}
            <span v-if='subAmount > courseForCusSelcet.course_amount' class='error--text'>
              {{ $t('wrong_number') }}
            </span></label
          >
          <v-text-field
            v-model='subAmount'
            :disabled='statusDisabledInputAmount'
            outlined
            autofocus
            type='number'
            dense
            @keypress.enter="subAmount == '' || subAmount > courseForCusSelcet.course_amount ? '' : sumCourse()"
          >
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="subAmount == '' || subAmount == '0' || subAmount > courseForCusSelcet.course_amount"
            color='primary'
            @click='sumCourse'
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn color='secondary' outlined @click='dialogInputAmount = false'>
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model='dialogEdit' persistent :max-width="'1100'">
      <v-card>
        <v-card-title>
          {{ $t('edit_drug_equipment') }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols='12' md='6'>
              <v-navigation-drawer
                v-if='statusLoadListDrugEdit'
                height='220px'
                width='100%'
                class='py-0 my-0'
                permanent
              >
                <v-list class='py-0 my-0'>
                  <v-list-item v-for='(item, index) in drugEquipmentEdit' :key='index'>
                    <v-list-item-content>
                      <v-list-item-title>
                        <DrugStatus :type='item.drug_category_id' />
                        <span>
                          {{ item.drug_id }}
                          {{ item.drug_name }}
                        </span>
                        <div v-if="statusCourseType == '3' && item.servingdetail_book_amount!='0'"
                             class='error--text mt-1'>
                          {{ $t('balance') }} :
                          {{ item.servingdetail_book_amount }}
                          {{ item.drug_unit }}
                        </div>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text class='d-flex'>
                        <v-text-field
                          v-model='item.servingdetail_temp_amount'
                          dense
                          outlined
                          type='number'
                          hide-details
                          :label="$t('amount')"
                          style='width: 150px'
                          :suffix='item.drug_unit'
                          @input='updateDrugEquipmentAmount(item)'
                        ></v-text-field>
                        <v-icon
                          style='cursor: pointer'
                          color='error'
                          class='ml-2'
                          @click='deleteDrugEquipmentOfCourse(item.servingdetail_temp_id)'
                        >
                          {{ icons.mdiClose }}
                        </v-icon>
                      </v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-navigation-drawer>
            </v-col>
            <v-col cols='12' md='6'>
              <v-autocomplete
                v-model='drug_id_pri'
                class='mt-3'
                :items='drugList'
                :label="$t('drug_list')"
                item-value='drug_id_pri'
                :no-data-text="`${$t('no_data')} !`"
                outlined
                dense
                :filter='customFilterDrugList'
                @change='addDrugEquipment'
              >
                <template v-slot:item='{ item }'>
                  ({{ item.drug_id }}) {{ item.drug_name }}
                  <v-spacer></v-spacer>
                  <span class='primary--text'>{{ $t('balance') }} {{ item.drug_total
                    }} {{ item.drug_unit }}</span>
                </template>
                <template v-slot:selection='{ item }'>
                  ({{ item.drug_id }})
                  {{ item.drug_name }}
                </template>
              </v-autocomplete>
              <v-autocomplete
                v-model='drug_id_pri'
                :label="$t('equipment_list')"
                :items='equipmentList'
                item-value='drug_id_pri'
                :no-data-text="`${$t('no_data')} !`"
                :filter='customFilterDrugList'
                dense
                class='mt-3'
                outlined
                @change='addDrugEquipment'
              >
                <template v-slot:item='{ item }'>
                  ({{ item.drug_id }})
                  {{ item.drug_name }}
                  <v-spacer></v-spacer>
                  <span class='primary--text'>{{ $t('balance') }} {{ item.drug_total
                    }} {{ item.drug_unit }}</span>
                </template>
                <template v-slot:selection='{ item }'>
                  ({{ item.drug_id }})
                  {{ item.drug_name }}
                </template>
              </v-autocomplete>
              <v-text-field
                v-model='serving_cost'
                type='number'
                dense
                class='mt-3'
                :label="$t('doctor_fee')"
                outlined
                @blur='updateDoctorFeeInCourse'
                @keypress.enter='updateDoctorFeeInCourse'
              >
              </v-text-field>
            </v-col>
            <!-- <v-col v-if="statusCourseType == 3" cols="12">
              <v-navigation-drawer v-if="statusLoadListDrugEdit" height="200px" permanent width="100%">
                <v-list>
                  <v-list-item v-for="(item, index) in drugEquipmentEdit" :key="index">
                    <v-list-item-content>
                      <v-list-item-title>
                        <DrugStatus :type="item.drug_category_id" />
                        <span>
                          {{ item.drug_id }}
                          {{ item.drug_name }}
                        </span>
                        <div class="error--text mt-1">
                          {{ $t('balance') }} :
                          {{ item.servingdetail_book_amount }}
                          {{ item.drug_unit }}
                        </div>
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-list-item-action-text class="d-flex">
                        <v-text-field
                          v-model="item.servingdetail_temp_amount"
                          dense
                          type="number"
                          outlined
                          hide-details
                          :label="$t('amount')"
                          style="width: 150px"
                          :suffix="item.drug_unit"
                          @keypress.enter="updateDrugEquipmentAmount(item)"
                          @blur="updateDrugEquipmentAmount(item)"
                        ></v-text-field>
                      </v-list-item-action-text>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </v-navigation-drawer>
              <v-divider></v-divider>
              <v-text-field
                v-model="serving_cost"
                type="number"
                dense
                :label="$t('doctor_fee')"
                outlined
                @blur="updateDoctorFeeInCourse"
                @keypress.enter="updateDoctorFeeInCourse"
              >
              </v-text-field>
            </v-col> -->
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color='primary' @click='saveEditDrug()' :disabled='loadingEditAmount'
                 :loading='loadingEditAmount'>
            {{ $t('save') }}
          </v-btn>
          <v-btn color='secondary' outlined @click='dialogEdit = false'>
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model.trim='dialogCancel' persistent max-width='400'>
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon color='warning' class='me-1'>
            {{ icons.mdiAlertOutline }}
          </v-icon>

          {{ $t('confirmCancelAll') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color='primary' :loading='cancelLoading' :disabled='cancelLoading'
                 @click='cancelAll()'>
            {{ $t('confirm') }}
          </v-btn>
          <v-btn color='red' outlined @click='dialogCancel = false'>
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiArrowRightThick,
  mdiClose,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiAlertOutline,
  mdiCheckOutline,
} from '@mdi/js'
import useServiceUasge from './useServiceUasge'
import { formatPrice } from '@/plugins/filters'
import DrugStatus from '@/components/basicComponents/DrugStatus.vue'
import CourseType from '@/components/basicComponents/CourseType.vue'

export default {
  filters: {
    formatPrice,
  },
  components: { DrugStatus, CourseType },

  setup() {
    return {
      ...useServiceUasge(),
      icons: {
        mdiArrowRightThick,
        mdiClose,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiAlertOutline,
        mdiCheckOutline,
      },
    }
  },
}
</script>

<style lang='scss' scoped></style>
